<template>
  <div ref="elRoot" class="animated-quote -fouc">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { SplitText as __SplitText } from '~/lib/gsap/plugins/SplitText'
import './quoteAnimationScroll.css'

const { pageTransitionState } = usePageTransition()
const { $gsap } = useNuxtApp()
const elRoot = ref()

const { context, contextSafe } = useGSAP(null, elRoot)

watch(
  () => pageTransitionState.pageTransitionComplete,
  (newValue) => {
    if (newValue) {
      initAnim()
    }
  },
)

function initAnim() {
  contextSafe((self: any) => {
    const elQuoteText = elRoot.value.querySelector('.quote_text')
    const elQuoteAuthor = elRoot.value.querySelector('.quote_author')

    const quoteText = new __SplitText(elQuoteText, {
      type: 'chars, words',
      wordsClass: 'word',
      charsClass: 'char',
    })

    elRoot.value.classList.toggle('-fouc', false)

    const tl = $gsap.timeline({
      scrollTrigger: {
        trigger: elRoot.value,
        start: 'top 80%',
        end: 'center center',
        scrub: true,
        // markers: true,
      },
    })
    tl.fromTo(
      elQuoteText,
      { '--before-opacity': 0 },
      {
        '--before-opacity': 1,
        ease: 'none',
      },
    )
    tl.fromTo(
      quoteText.chars,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.8,
        stagger: 0.03,
        ease: 'none',
      },
    )
    tl.fromTo(
      elQuoteText,
      { '--after-opacity': 0 },
      {
        '--after-opacity': 1,
        ease: 'none',
      },
    )
    tl.fromTo(
      elQuoteAuthor,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      '-=0.5',
    )
  })
}
</script>
